<script setup lang="ts">
const { locale } = useI18n();

const isLanguageMenuOpen = ref(false);
const menuHtmlElement = ref(null);
const ignoreElRef = ref(null);

onClickOutside(menuHtmlElement, () => (isLanguageMenuOpen.value = false), { ignore: [ignoreElRef] });
</script>

<template>
    <div class="relative hidden lg:block">
        <button
            ref="ignoreElRef"
            :title="$t('changeLanguage')"
            class="bg-transparent py-3 text-2xl inline-flex items-center px-3.5"
            aria-expanded="false"
            aria-haspopup="true"
            @click.stop="isLanguageMenuOpen = !isLanguageMenuOpen"
        >
            <span class="uppercase">{{ locale }}</span>
            <SvgoAngleDown class="h-4.5 w-auto text-gray-500 ml-1" />
            <span class="sr-only">Open language menu</span>
        </button>

        <ClientOnly>
            <Transition
                enterActiveClass="transition ease-out duration-150"
                enterFromClass="transform opacity-0 scale-95"
                enterToClass="transform opacity-100 scale-100"
                leaveActiveClass="transition ease-in duration-100"
                leaveFromClass="transform opacity-100 scale-100"
                leaveToClass="transform opacity-0 scale-95"
            >
                <div
                    v-show="isLanguageMenuOpen"
                    ref="menuHtmlElement"
                    class="block absolute rounded-md shadow bg-white z-20 left-4 top-12 w-34 py-1 px-3"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabindex="-1"
                >
                    <LayoutLanguageList class="!mb-0" />
                </div>
            </Transition>
        </ClientOnly>
    </div>
</template>
